import React, { useState, useEffect, Fragment } from 'react';
import Glitch from 'glitch-javascript-sdk';
import Header from '../../component/layout/header';
import Footer from '../../component/layout/footer';
import PageHeader from '../../component/layout/pageheader';
import CommunityFormBasic from '../../component/section/communities/form_community_basic';
import Loading from '../../component/alerts/Loading';
import timeouts from '../../../../constants/timeouts';
import Danger from '../../component/alerts/Danger';
import Navigate from '../../../../util/Navigate';
import withRouter from '../../../../util/withRouter';
import PublisherHeader from '../../component/layout/publisherheader';
import { Link, useNavigate } from 'react-router-dom';

const PublisherOnboardinStep4Page = (props) => {
    const [community, setCommunity] = useState({});
    const navigate = useNavigate();

    const handleCreateCampaign = () => {
        navigate(Navigate.campaignsCreatePage());
    };

    const handleCreateCommunity = () => {
        navigate(Navigate.communitiesGettingStartedPage(community.id));
    };

    const handleNewsletter = () => {
        navigate(Navigate.newsletterCreatePage());
    };

    const handleMediakit = () => {
        navigate(Navigate.mediakitsPublishersCreatePage());
    };

    const handleTesters = () => {
        navigate(Navigate.playtestingPublishersListPage());
    };

    const handleSocial = () => {
        navigate(Navigate.schedulerCreatePage());
    };

    const handleAnalytics = () => {
        navigate(Navigate.reportsMainPage());
    };

    const handleChat = () => {
        navigate(Navigate.titlesChatPage());
    };

    useEffect(() => {
        Glitch.api.Communities.list({ roles: [1, 2], order_by: 'created_at' })
            .then(response => {
                if (response.data.data.length > 0) {
                    setCommunity(response.data.data[0]);
                }
            })
            .catch(error => { });
    }, []);

    return (
        <div className="d-flex flex-column justify-content-center align-items-center text-center h-100 p-3">
            <div className="text-white mt-4">
                <h2>Registration Final Step</h2>
                <p className="lead">Congratulations on completing your registration! Watch the brief intro video below and decide what you'd like to do next.</p>
            </div>

            <div className="row justify-content-center w-100">
                <div className="col-md-8 col-12">
                    <div className="ratio ratio-16x9 border border-white border-2">
                        <iframe
                            src="https://www.youtube.com/embed/GIMQ1kLszWk"
                            title="Getting Started with Glitch"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                        ></iframe>
                    </div>
                </div>
            </div>

            <hr className="my-4 w-100" />

            <div>
                <h3>What would you like to do next?</h3>
            </div>

            <div className="d-flex flex-wrap justify-content-center mb-5 mt-4" style={{ gap: '20px' }}>
                <button
                    type="button"
                    className="btn btn-danger btn-lg p-4"
                    style={{ minWidth: '150px', maxWidth: '300px', fontSize: '18px' }}
                    onClick={handleChat}
                >
                    <i className="fas fa-2x fa-comments"></i><br />AI Chat
                </button>
                <button
                    type="button"
                    className="btn btn-danger btn-lg p-4"
                    style={{ minWidth: '150px', maxWidth: '300px', fontSize: '18px' }}
                    onClick={handleAnalytics}
                >
                    <i className="fas fa-2x fa-chart-pie"></i><br />Access Analytics
                </button>
                <button
                    type="button"
                    className="btn btn-success btn-lg p-4"
                    style={{ minWidth: '150px', maxWidth: '300px', fontSize: '18px' }}
                    onClick={handleSocial}
                >
                    <i className="fas fa-2x fa-thumbs-up"></i><br />Automate Social Media
                </button>
                <button
                    type="button"
                    className="btn btn-primary btn-lg p-4"
                    style={{ minWidth: '150px', maxWidth: '300px', fontSize: '18px' }}
                    onClick={handleCreateCampaign}
                >
                    <i className="fas fa-2x fa-bullhorn"></i><br />Find Influencers
                </button>
            </div>
            <div className="d-flex flex-wrap justify-content-center mb-5" style={{ gap: '20px' }}>

                <button
                    type="button"
                    className="btn btn-secondary btn-lg p-4"
                    style={{ minWidth: '150px', maxWidth: '300px', fontSize: '18px' }}
                    onClick={handleNewsletter}
                >
                    <i className="fas fa-2x fa-newspaper"></i><br />Start A Newsletter
                </button>
                <button
                    type="button"
                    className="btn btn-secondary btn-lg p-4"
                    style={{ minWidth: '150px', maxWidth: '300px', fontSize: '18px' }}
                    onClick={handleMediakit}
                >
                    <i className="fas fa-2x fa-photo-video"></i><br />Create A Media Kit
                </button>
                <button
                    type="button"
                    className="btn btn-secondary btn-lg p-4"
                    style={{ minWidth: '150px', maxWidth: '300px', fontSize: '18px' }}
                    onClick={handleTesters}
                >
                    <i className="fas fa-2x fa-vial"></i><br />Find Testers
                </button>
            </div>


            <div className="container mb-5">
                <p className="lead text-center">
                    Would you like to start an influencer campaign, find testers, build a community, create a media kit, or start a newsletter?
                    <br /><br />
                    An influencer campaign will help you find influencers to market your game. A community will allow you to build a forum-like system where users can engage in an environment focused entirely on your game.
                </p>
            </div>
        </div>
    );
};

export default PublisherOnboardinStep4Page;
